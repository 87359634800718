import React from "react";
import { useEffect } from 'react';

const useScript = url => {
    useEffect(() => {
        const script = document.createElement('script');

        script.src = url;
        script.async = true;

        document.body.appendChild(script);

        return () => {
            document.body.removeChild(script);
        }
    }, [url]);
};

export const Feed = (props) => {
    useScript("https://embedsocial.com/cdn/ht.js")

    return (
        <div id='feed' className='text-center'>
            <div className='container'>
                <div className='section-title'>
                    <div className="ig-iframe">
                        <h2>Instagram Feed</h2>
                        <div className="embedsocial-hashtag" data-ref="37b5a778c5605f45a21f9ff9a7c563a9c722ba78"/>
                    </div>
                </div>
            </div>
        </div>
    )
}