import Collapsible from "react-collapsible";

export const Services = (props) => {
  return (
    <div id='services' className='text-center'>
      <div className='container'>
        <div className='section-title'>
          <h2>Our Services</h2>
          <p>
            Interior and exterior auto detailing options tailored to you.
          </p>
          <p>
            We will customize our offerings to meet your specific needs.
          </p>
        </div>
        <div className='row'>
          {props.data
            ? props.data.map((d, i) => (
                <div key={`${d.name}-${i}`} className='col-md-4'>
                  {' '}
                  <i className={d.icon}></i>
                  <div className='service-row'>
                    {d.text.map((detailPackage, i) => (
                      <Collapsible trigger={detailPackage.title} key={`"service-package-"${i}`}>
                        <div className='service-desc'>
                          <ul>
                            {detailPackage.details.map((item, i) => (
                                <li key={`${item}-${i}`}>{item}</li>
                            ))}
                          </ul>
                        </div>
                        <div className='service-price'>
                          <ul>
                            {detailPackage.price.map((item, i) => (
                                <li key={`${item}-${i}`}>{item}</li>
                            ))}
                          </ul>
                        </div>
                      </Collapsible>
                    ))}
                  </div>
                </div>
              ))
            : 'loading'}
        </div>
      </div>
    </div>
  )
}
