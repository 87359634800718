export const Social = (props) => {
    return (
        <div id='social' className='text-center'>
            <div className='container'>
                <div className='section-title'>
                    <h2>Social Media</h2>
                    <p>{props.data ? props.data.paragraph : 'Loading...'}</p>
                </div>
                <div className='row' >
                    {props.data
                        ? props.data.accounts.map((d, i) => (
                            <div key={`${d.title}-${i}`} className='col-md-6'>
                                {' '}
                                <a href={d.link}>
                                    <i className={d.icon}></i>
                                    <p>{d.title}</p>
                                </a>
                            </div>
                        ))
                        : 'Loading...'}
                </div>
            </div>
        </div>
    )
}
