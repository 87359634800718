export const Header = (props) => {
  return (
    <header id='header'>
      <div className='intro'>
        <div className='overlay'>
          <div className='container'>
            <div className='row'>
              <div className='col-md-8 col-md-offset-2 intro-text'>
                {props.data ?
                  <img
                      src={props.data.logo}
                      className='img-responsive'
                      alt={props.data.title}
                  /> : 'Loading'}
                <div className='intro-paragraph'>
                  {props.data
                     ? props.data.paragraph.map((d, i) => (
                        <p key={`${d}-${i}`}>{d}</p>
                     ))
                     : 'loading'}
                </div>
                <a
                  href='#contact'
                  className='btn btn-custom btn-lg page-scroll'
                >
                  Schedule Now
                </a>{' '}
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  )
}
